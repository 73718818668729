(function (window) {
  'use strict';
  // eslint-disable-next-line no-undef,no-prototype-builtins
  if (!_uptime_rum2 || !_uptime_rum2.hasOwnProperty('uuid')) {
    console.warn('You are missing _uptime_rum2.uuid property which needs to be global.');
    return;
  }

  const MAX_TTI_WAIT_TIME = 35000,
    DEFAULT_SESSION_TIMEOUT = 1800000; // 30 minutes

  var mainReportSent = false,
    ttiStart = 0,
    activeAjaxCount = 0,
    lastAjaxTime = 0,
    navigationTimer = 0,
    // eslint-disable-next-line no-undef
    allowedExternalDomains = _uptime_rum2.extra || [],
    // eslint-disable-next-line no-undef
    isAjaxDisabled = _uptime_rum2.ajax === undefined ? false : !_uptime_rum2.ajax;

  function getTimingDataFromPerformanceEntry(entry) {
    var timing = {};
    var keys = [
      'redirectStart',
      'redirectEnd',
      'fetchStart',
      'domainLookupStart',
      'domainLookupEnd',
      'connectStart',
      'secureConnectionStart',
      'connectEnd',
      'requestStart',
      'responseStart',
      'responseEnd',
      'domInteractive',
      'domContentLoadedEventEnd',
      'domComplete',
      'loadEventStart',
      'loadEventEnd',
      'redirectCount',
    ];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i],
        val = entry[key];
      if (val > 0) {
        timing[key] = Math.round(val - entry.startTime);
      } else if (entry.entryType === 'navigation' && document.readyState !== 'complete') {
        timing[key] = MAX_TTI_WAIT_TIME;
      } else {
        timing[key] = 0;
      }
    }
    keys = ['duration', 'transferSize', 'encodedBodySize', 'decodedBodySize'];
    for (let i = 0; i < keys.length; i++) {
      timing[keys[i]] = entry[keys[i]];
    }
    return timing;
  }

  function isCacheHit(entry) {
    if (entry.transferSize > 0) return false;
    if (entry.decodedBodySize > 0) return true;
    return entry.duration < 30;
  }

  function getPerformanceMetrics() {
    var performance = window.performance || window.webkitPerformance || window.msPerformance || window.mozPerformance;

    if (performance === undefined) {
      return false;
    }
    const pnt = performance.getEntriesByType('navigation')[0];
    var timing = getTimingDataFromPerformanceEntry(pnt);
    return timing || false;
  }

  function getAjaxMetrics(url) {
    var res = null,
      entries = window.performance.getEntriesByType('resource');

    for (let i = entries.length - 1; i >= 0; i--) {
      const entry = entries[i];
      if (
        ['xmlhttprequest', 'fetch'].indexOf(entry.initiatorType) === -1 ||
        (url !== undefined && entry.name !== url)
      ) {
        continue;
      }
      res = getTimingDataFromPerformanceEntry(entry);
      if (entries.length >= 150) {
        window.performance.clearResourceTimings();
      }
      break;
    }
    return res;
  }

  function getPaintMetrics() {
    var entries = window.performance.getEntriesByType('paint'),
      res = {
        firstPaint: 0,
        firstContentfulPaint: 0,
      };
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.name === 'first-paint') {
        res['firstPaint'] = Math.round(entry.startTime);
      } else if (entry.name === 'first-contentful-paint') {
        res['firstContentfulPaint'] = Math.round(entry.startTime);
      }
    }
    return res;
  }

  function getSessionId() {
    var sdb = window.sessionStorage,
      now = new Date(),
      // eslint-disable-next-line no-undef
      sessionTimeout = _uptime_rum2.sessionTimeout || DEFAULT_SESSION_TIMEOUT;
    if (!sdb) {
      return 0;
    }
    if (
      !sdb._uptime_rum2_sid_time ||
      now.getTime() - sdb._uptime_rum2_sid_time > sessionTimeout ||
      now.getTime() - sdb._uptime_rum2_sid_start > 86400000
    ) {
      sdb._uptime_rum2_sid_start = now.getTime();
      sdb._uptime_rum2_sid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      // eslint-disable-next-line no-undef
      _uptime_rum2.debug && console.debug('New session ID', sdb._uptime_rum2_sid);
    }
    sdb._uptime_rum2_sid_time = now.getTime();
    return parseInt(sdb._uptime_rum2_sid, 10);
  }

  function createCORSRequest(method, url) {
    var xhr = new XMLHttpRequest();
    if (xhr.withCredentials !== undefined) {
      // XHR for Chrome/Firefox/Opera/Safari.
      xhr.open(method, url, true);
    } else if (typeof XDomainRequest !== 'undefined') {
      // XDomainRequest for IE.
      // eslint-disable-next-line no-undef
      xhr = new XDomainRequest();
      xhr.open(method, url);
    } else {
      // CORS not supported.
      xhr = null;
    }
    return xhr;
  }

  function startTracking() {
    // waiting for 1.2 seconds after load event or any subsequent AJAX events before assuming
    // that page is loaded, every AJAX request within this interval resets wait timer
    window.setTimeout(checkNavigation, 1200);
    mainReportSent = false;
    lastAjaxTime = 0;
    navigationTimer = 0;
    ttiStart = new Date();
    lastAjaxTime = ttiStart;
    // eslint-disable-next-line no-undef
    _uptime_rum2.debug && console.debug('RUM: Starting TTI tracking', ttiStart);
  }

  function checkNavigation() {
    var now = new Date();
    if (
      (activeAjaxCount === 0 && now - lastAjaxTime >= 1200 && document.readyState === 'complete') ||
      navigationTimer >= MAX_TTI_WAIT_TIME
    ) {
      sendMainPageEvent();
    } else {
      navigationTimer += 1200;
      window.setTimeout(checkNavigation, 1200);
    }
  }

  function sendPayload(payload) {
    // eslint-disable-next-line no-undef
    payload.uuid = _uptime_rum2.uuid;
    payload.sid = getSessionId();
    if (payload.isJSError === undefined) {
      // eslint-disable-next-line no-undef
      payload.isJSError = _uptime_rum2.reportState.isJSError;
    }
    if (payload.isHTTPError === undefined) {
      // eslint-disable-next-line no-undef
      payload.isHTTPError = _uptime_rum2.reportState.isHTTPError;
    }
    if (payload.status === undefined) {
      // eslint-disable-next-line no-undef
      payload.status = _uptime_rum2.reportState.status;
    }
    if (payload.ua === undefined) {
      // eslint-disable-next-line no-undef
      payload.ua = _uptime_rum2.reportState.ua;
    }

    // eslint-disable-next-line no-undef
    var xhr = createCORSRequest('POST', _uptime_rum2.url);
    if (!xhr) {
      return false;
    }
    xhr.onload = function () {
      // eslint-disable-next-line no-undef
      _uptime_rum2.debug && console.debug('RUM: Collector response', xhr.response);
    };
    xhr.send(JSON.stringify(payload));
    return true;
  }

  function sendMainPageEvent() {
    // eslint-disable-next-line no-undef
    _uptime_rum2.debug && console.debug('RUM: Sending MainPage event');
    if (mainReportSent) {
      return false;
    }

    try {
      var timing = getPerformanceMetrics(),
        paint = getPaintMetrics();
      // calculating TTI as load time + time we waited until last AJAX call since load event
      var loadTime = timing.domContentLoadedEventEnd;
      var tti = loadTime + (lastAjaxTime - ttiStart);
      tti = Math.max(tti, paint.firstContentfulPaint, paint.firstPaint);
      var pageLoad = Math.max(tti, timing.loadEventEnd);

      // eslint-disable-next-line no-undef
      if (_uptime_rum2.errors.length > 0) {
        // eslint-disable-next-line no-undef
        _uptime_rum2.reportState.isJSError = true;
      }

      if (timing) {
        var payload = {
          URL: window.location.href,
          title: document.title,
          isAjax: false,
          performance: {
            navigation: timing,
            paint: paint,
            extra: {
              tti: tti,
              pageLoad: pageLoad,
            },
          },
        };
        if (!isCacheHit(timing)) {
          sendPayload(payload);
        }
      } else {
        console.error('RUM: No timing data available');
      }

      // eslint-disable-next-line no-undef
      _uptime_rum2.errors.slice(0, 0);
      mainReportSent = true;
      return true;
    } catch (e) {
      // Something went wrong. Only by showing no data will we know if a JS error is happening
      // eslint-disable-next-line no-undef
      _uptime_rum2.debug && console.error('RUM: Error sending RUM performance data', e);
      return false;
    }
  }

  function sendAjaxEvent(url, status, requestTime) {
    if (isAjaxDisabled) {
      // eslint-disable-next-line no-undef
      _uptime_rum2.debug && console.debug('RUM: Skipping sending AJAX event because AJAX is disabled by config');
      return;
    }

    // eslint-disable-next-line no-undef
    _uptime_rum2.debug && console.debug('RUM: Sending AJAX event');
    // check if there are JS errors after current AJAX call
    var ajaxJSError = false,
      ajaxHTTPError = true;

    if (status === 0 || (status >= 200 && status < 400)) {
      ajaxHTTPError = false;
    }

    // eslint-disable-next-line no-undef
    if (_uptime_rum2.errors.length > 0) {
      // eslint-disable-next-line no-undef
      const lastErrorTime = _uptime_rum2.errors[_uptime_rum2.errors.length - 1].t;
      if (lastErrorTime > requestTime) {
        ajaxJSError = true;
      }
    }
    var timings = getAjaxMetrics(url);
    if (timings == null) {
      // eslint-disable-next-line no-undef
      _uptime_rum2.debug && console.debug('RUM: AJAX metrics not found for URL ', url);
      return;
    }
    var payload = {
      URL: url,
      isAjax: true,
      isJSError: ajaxJSError,
      isHTTPError: ajaxHTTPError,
      status: status,
      mainURL: window.location.href,
      performance: {
        navigation: timings,
      },
    };
    if (!isCacheHit(timings)) {
      sendPayload(payload);
    }
  }

  function resetReportState() {
    // eslint-disable-next-line no-undef
    _uptime_rum2.reportState = {
      isJSError: false,
      isHTTPError: false,
      status: 200,
      ua: window.navigator.userAgent,
    };
    // eslint-disable-next-line no-undef
    if (_uptime_rum2.override !== undefined) {
      // eslint-disable-next-line no-undef
      for (const [key, value] of Object.entries(_uptime_rum2.override)) {
        // eslint-disable-next-line no-undef
        _uptime_rum2.reportState[key] = value;
      }
    }
  }

  function shouldReportAjaxCall(toURL) {
    if (!toURL.startsWith('http')) {
      return true;
    }
    // filter 3rd party domains
    var u = new URL(toURL);
    return !(!u.hostname.endsWith(document.location.hostname) && allowedExternalDomains.indexOf(u.hostname) === -1);
  }

  // Main initialization
  resetReportState();
  // eslint-disable-next-line no-undef
  _uptime_rum2.sendPayload = sendPayload;

  // mock XMLHttpRequest to track AJAX requests
  XMLHttpRequest.prototype.realOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function (method, url) {
    const urlStr = url.toString();
    // eslint-disable-next-line no-undef
    if (urlStr.startsWith(_uptime_rum2.url) || !shouldReportAjaxCall(urlStr)) {
      // ignore request to our own collector and 3rd party domains
      return this.realOpen.apply(this, arguments);
    }
    activeAjaxCount++;
    // eslint-disable-next-line no-undef
    _uptime_rum2.debug && console.debug('RUM: AJAX started ', urlStr, new Date());
    this.addEventListener(
      'loadend',
      function () {
        activeAjaxCount--;
        lastAjaxTime = new Date();
        // eslint-disable-next-line no-undef
        if (!_uptime_rum2.reportState.isHTTPError) {
          // eslint-disable-next-line no-undef
          _uptime_rum2.reportState.status = this.status;
          // eslint-disable-next-line no-undef
          _uptime_rum2.reportState.isHTTPError = !(this.status === 0 || (this.status >= 200 && this.status < 400));
        }
        // eslint-disable-next-line no-undef
        _uptime_rum2.debug && console.debug('RUM: AJAX ready', this.responseURL, this.status, new Date());
        window.setTimeout(sendAjaxEvent, 1200, this.responseURL, this.status);
      },
      false
    );

    return this.realOpen.apply(this, arguments);
  };

  // mock fetch to track AJAX requests
  const realFetch = window.fetch;
  window.fetch = function () {
    const url = arguments[0];
    const urlStr = url.toString();
    // eslint-disable-next-line no-undef
    if (urlStr.startsWith(_uptime_rum2.url) || !shouldReportAjaxCall(urlStr)) {
      // ignore request to our own collector and 3rd party domains
      return realFetch.apply(this, arguments);
    }
    activeAjaxCount++;
    // eslint-disable-next-line no-undef
    _uptime_rum2.debug && console.debug('RUM: Fetch started ', urlStr, new Date());
    return new Promise((resolve, reject) => {
      realFetch
        .apply(this, arguments)
        .then((response) => {
          activeAjaxCount--;
          lastAjaxTime = new Date();
          // eslint-disable-next-line no-undef
          _uptime_rum2.reportState.isHTTPError = response.ok;
          // eslint-disable-next-line no-undef
          _uptime_rum2.reportState.status = response.status;
          // eslint-disable-next-line no-undef
          _uptime_rum2.debug && console.debug('RUM: Fetch ready', response.url, response.status, new Date());
          window.setTimeout(sendAjaxEvent, 1200, response.url, response.status, lastAjaxTime);
          resolve(response);
        })
        .catch((error) => {
          activeAjaxCount--;
          reject(error);
        });
    });
  };

  // record unhandled errors in promises
  window.addEventListener('unhandledrejection', function (event) {
    window._uptime_rum2.errors.push({t: new Date(), err: event});
  });

  startTracking();
})(window);
